window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Vue from "vue";
import Vuelidate from 'vuelidate';
import {BootstrapVue} from 'bootstrap-vue';
import Registartion from './registration';
import Login from './login';


Vue.use(BootstrapVue)
Vue.use(Vuelidate);

Vue.component('auth', {
    name: "auth",
    component: {
        Registartion,
        Login
    },
});

var app = new Vue({
    el: '#app',
})