import Vue from "vue";
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'

export default Vue.component('registration', {
    name: "registration",
    validations: {
        firstName: {
            required,
            maxLength: maxLength(255),
        },
        lastName: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
            maxLength: maxLength(255),
        },
        password: {
            required,
            minLength: minLength(6)
        },
        passwordConfirm: {
            required,
            sameAsPassword: sameAs('password')
        },
        eula: {
            required,
        },
    },
    data: function () {
        return {
            isTooltip: true,
            isLoading: false,
            fullPage: false,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            eula: null,
            newsletter: false,
            errors: []
        };
    },

    watch: {
        eula(to) {
            if (to) {
                this.errors = [];
            }
        }
    },

    methods: {
        facebook() {
            const facebookUrl = '/login/facebook';
            return this.socialAuth(facebookUrl)
        },

        google() {
            const facebookUrl = '/login/google';
            return this.socialAuth(facebookUrl)
        },

        socialAuth(url) {
            if (!this.eula) {
                this.errors = [];
                return this.errors.push('Az Általános szerződési Feltételeket és az Adatvédelmi Nyilatkozatot elfogadása kötelező');;
            }
            this.isLoading = true;
            axios.get(url)
                .then(function (response) {
                    window.location.replace(response.data);
                }.bind(this))
                .catch(error => {
                    this.isLoading = flase;
                });
        },

        registrationSuccessfull() {

            window.swal.fire({
                title: '<h1>Regisztráció</h1>',
                type: 'success',
                html: 'Gratulálunk ön sikeresen regisztrált az oldalunkira',
                showCloseButton: false,
                showCancelButton: false,
                padding: '3rem',
                confirmButtonText: 'Rendben',
                focusConfirm: true,
                onBeforeOpen: () => {
                    $('#registration').modal('hide');
                },
                onClose: () => {
                    window.location.reload();
                }
            })
            
            this.isLoading = false;
        },

        showErrors(rErrors) {
            this.errors = [];
            for (var propertyName in rErrors) {
                var messages = rErrors[propertyName];
                for (var msg in messages) {
                    this.errors.push(messages[msg]);
                }
            }
        },

        registration(event) {
            event.preventDefault();

            this.isLoading = true;

            this.$v.$touch();

            if (this.$v.$anyError) {
                return this.isLoading = false;
            }

            axios.post('/register', {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordConfirm,
                eula: this.eula,
                newsletter: this.newsletter
            }).then(function (response) {
                console.log(response);
                this.registrationSuccessfull()
            }.bind(this)).catch(function (error) {

                if (error.response.status == 422) {
                    this.showErrors(error.response.data.errors);

                } else {
                    console.log(error.response);
                }

                this.isLoading = false;
            }.bind(this));
        }
    },
});
