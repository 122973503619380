import Vue from "vue";
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default Vue.component('login', {
    name: "login",
    validations: {
        email: {
            required,
            email,
            maxLength: maxLength(255),
        },
        password: {
            required,
            minLength: minLength(6)
        },
    },
    data: function () {
        return {
            isLoading: false,
            fullPage: false,
            isTooltip: true,

            email: "",
            password: "",
            remeberMe: false,
            errors: []
        };
    },

    methods: {
        facebook() {
            const facebookUrl = '/login/facebook';
            return this.socialAuth(facebookUrl)
        },

        google() {
            const facebookUrl = '/login/google';
            return this.socialAuth(facebookUrl)
        },

        socialAuth(url) {
            this.isLoading = true;
            axios.get(url)
                .then(function (response) {
                    window.location.replace(response.data);
                }.bind(this))
                .catch(error => {
                    this.isLoading = flase;
                });
        },

        showErrors(rErrors) {
            this.errors = [];
            for (var propertyName in rErrors) {
                var messages = rErrors[propertyName];
                for (var msg in messages) {
                    this.errors.push(messages[msg]);
                }
            }
        },

        login(event) {
            event.preventDefault();

            this.isLoading = true;

            this.$v.$touch();

            if (this.$v.$anyError) {
                return this.isLoading = false;
            }

            axios.post('/login', {
                email: this.email,
                password: this.password,
                remember: this.remeberMe
            }).then(function (response) {
                window.location.replace(response.data);
            }.bind(this))
                .catch(function (error) {
                if (error.response.status == 422) {
                    this.showErrors(error.response.data.errors);

                } else {
                    console.log(error.response);
                }
                this.isLoading = false;
            }.bind(this));
        }
    },
});
